<template>
	<div
		class="select-none relative cursor-pointer overflow-hidden z-40 flex group"
	>
		<input
			v-model="computedValue"
			:class="['h-[60px] w-full bg-white-100 appearance-none z-10 checked:bg-green-500 rounded-lg transition-all duration-300 group-active:transform group-active:scale-75 cursor-pointer', { 'pt-[100%] w-full': hasIcons }]"
			:value="value"
			:type="type"
			ontouchstart=""
		>
		<p
			class="absolute left-0 right-0 mx-auto h-full text-xs flex flex-col justify-center items-center pointer-events-none transition-all duration-300 group-active:transform group-active:scale-75 z-50 pt-4 desktop:text-base"
		>
			<InlineSvg
				v-if="hasIcons"
				:src="require(`@/assets/svg/${iconFileName}?raw`)"
				fill="white"
				class="w-1/4 z-20 pointer-events-none group-active:transform group-active:scale-75 transition-all duration-300 mb-4"
			/>
			<span :class="['font-light text-white-500', {'w-full text-center': hasIcons}, {'ml-4': !hasIcons}]">{{ label }}</span>
		</p>
	</div>
</template>
<script>
import { computed } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default {

	components: {
		InlineSvg,
	},

	props: {
		value: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		hasIcons: {
			type: Boolean,
			default: false,
		},
		iconFileName: {
			type: String,
			default: '',
		},
		modelValue: {
			type: Array,
			default: () => ([]),
		},
	},

	setup(props, context) {
		const computedValue = computed({
			get() {
				return props.modelValue;
			},

			set(value) {
				context.emit('update:modelValue', value);
			},
		});

		return { computedValue };
	},
};
</script>

import { CardField } from './CardField';
import { InfoListItem } from './InfoListItem';

export interface Card {
	cardType: string,
	id: string,
	title: string | ((answers: Record<string, string | number | string[]>, meta: { express: boolean }) => string),
	fields: CardField[],
	note?: string,
	emoji?: string,
	outlineTitle?: string,
	buttonText?: string,
	videoFileName?: string,
	infoList?: InfoListItem[],
}

export type InfoCard = Card & Required<Pick<Card, 'videoFileName' | 'infoList'>> & {
	cardType: 'infoCard' | 'successCard',
}

export const isVideoCard = (card: Card): card is InfoCard => Object.keys(card).includes('videoFileName');

export const getCardTitle = (
	card: Card,
	answers: Record<string, string | number | string[]>,
	meta: { express: boolean },
): string => {
	if (typeof card.title === 'string') {
		return card.title;
	}

	return card.title(answers, meta);
};

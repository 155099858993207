<template>
	<button :class="['bg-orange-500 font-medium uppercase px-2 text-xs tracking-[3px] text-white-500 flex justify-center items-center h-[60px] transition-all duration-500 desktop:text-base desktop:h-[72px]', {'w-full rounded-full': fullWidthButton }, {'w-1/2 rounded-r-full': nextButton }, {'w-1/2 border-r-[1px] border-solid border-white-500 rounded-l-full': backButton }]">
		<slot />
	</button>
</template>

<script>
export default {

	props: {

		fullWidthButton: {
			type: Boolean,
			default: false,
		},

		nextButton: {
			type: Boolean,
			default: false,
		},

		backButton: {
			type: Boolean,
			default: false,
		},
	},

};
</script>

<style>

</style>

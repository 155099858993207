<template>
	<div
		class="relative cursor-pointer overflow-hidden z-40"
	>
		<p
			class="flex items-center transition-all ease-in-out duration-500 text-white-300 tracking-[3px] mb-3"
		>
			<span class="z-10 font-light uppercase text-[10px] desktop:text-sm">{{ label }}</span>
		</p>
		<input
			v-model="value"
			:class="['relative w-full text-white-500 bg-white-100 appearance-none z-10 rounded-lg transition ease-in-out duration-500 h-11 pl-6 outline-none border-2 border-solid text-xs font-light placeholder-white-200 desktop:text-base desktop:h-14', { 'border-red-600 mb-2': isInvalid }, { 'border-green-400': meta.valid },]"
			:type="type"
			:name="name"
			:rules="rules"
			:placeholder="placeholder"
			:inputmode="inputmode"
			@blur="handleBlur()"
			@keypress.enter.prevent
		>

		<ErrorMessage
			v-if="isInvalid"
			class="text-red-600 text-xs text-left desktop:text-sm"
			as="p"
			:name="name"
		/>
	</div>
</template>
<script>
import {
	computed, onMounted, watch,
} from 'vue';
import { ErrorMessage, useField } from 'vee-validate';

export default {

	components: {
		ErrorMessage,
	},

	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		modelValue: {
			type: Array,
			default: () => ([]),
		},
		rules: {
			type: Object,
			required: true,
		},
		name: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			required: true,
		},
		inputmode: {
			type: String,
			default: '',
		},
	},

	setup(props, context) {
		const computedValue = computed({
			get() {
				return props.modelValue;
			},

			set(value) {
				context.emit('update:modelValue', [value]);
			},
		});

		const {
			errorMessage, meta, handleBlur, validate, value,
		} = useField(props.name, props.rules, { initialValue: props.modelValue });

		const isInvalid = computed(() => {
			if (meta.validated && meta.touched) {
				return !meta.valid;
			}

			return false;
		});

		watch(value, (newValue) => {
			context.emit('update:modelValue', [newValue]);
		});

		onMounted(
			async () => {
				if (value.value) {
					await validate();
				}
			},
		);

		return {
			computedValue, errorMessage, meta, handleBlur, value, isInvalid,
		};
	},
};
</script>
<style scoped>
input:checked + p {
	@apply text-white-500;
}

input:focus + p {
	@apply -top-3 text-sm;
}
</style>


import { defineComponent } from 'vue';
import { automationDeveloperCards } from '@/data/AutomationDeveloper';
import AppCardCollection from './components/AppCardCollection.vue';

export default defineComponent({
	name: 'App',
	components: {
		AppCardCollection,
	},
	setup() {
		const cards = automationDeveloperCards;

		return {
			cards,
		};
	},

});

import ky from 'ky';

export const addNewCandidate = (answers) => {
	const results = {
		candidate: {
			name: answers.personalData.name[0],
			email: answers.personalData.email[0],
			cover_letter: `Coding Journey: ${answers.codingJourney.gradutation},
                Berufserfahrung: ${answers.experience.years} years,
                Tools: ${answers.usedTools.tool},
                Gehaltsvorstellung: ${answers.checkOut.salary},
                Referenzen: ${answers.checkOut.references}`,
			open_question_answers: [
				{
					open_question_id: 1449407,
					content: answers.checkOut.salary,
				},
				{
					open_question_id: 1449408,
					content: '-',
				},
				{
					open_question_id: 1449409,
					content: '-',
				},
			],
		},
	};

	return ky.post(`https://${process.env.VUE_APP_COMPANY}.recruitee.com/api/offers/${process.env.VUE_APP_JOB_OFFER_NAME}/candidates`, { json: results }).json();
};

export const fetchOfferData = async () => {
	const response = await ky.get(`https://${process.env.VUE_APP_COMPANY}.recruitee.com/api/offers/${process.env.VUE_APP_JOB_OFFER_NAME}`).json();
	// eslint-disable-next-line
	console.log('Recruitee Stellenanzeige', response);
};


import {
	computed, ref, defineComponent, PropType,
} from 'vue';
import InlineSvg from 'vue-inline-svg';
import { Card } from '@/models/Card';
import { UserAnswers } from '@/models/UserAnswers';
import AppCard from './AppCard.vue';
import SunlabLogo from './svg/SunlabLogo.vue';

export default defineComponent({

	components: {
		AppCard,
		SunlabLogo,
		InlineSvg,
	},

	props: {
		items: {
			type: Array as PropType<Card[]>,
			default: () => ([]),
		},
	},

	setup(props) {
		const activeIndex = ref(0);
		const expressToggle = ref(false);
		const allItems = computed(() => props.items);

		// Animation State
		const cardState = ref('');

		const meta = computed(() => ({ express: expressToggle.value }));

		// Filter for fields with with options inside
		const filterAnswerFields = computed(() => allItems.value.filter((item) => item.fields.length));

		const userAnswer = ref([]);

		// Create the answers array for the v-model
		const createAnswersFromCards = (cards: Card[]): UserAnswers => cards.reduce((object, card) => ({
			...object,
			[card.id]: card.fields.reduce((optionsObject, field) => ({ ...optionsObject, [field.id]: [] }), {}),
		}), {} as UserAnswers);
		const userAnswers = ref<UserAnswers>(createAnswersFromCards(filterAnswerFields.value));

		// Define the active card
		const activeItem = computed(() => allItems.value[activeIndex.value]);
		// Define card ammount
		const ammountOfCards = computed(() => allItems.value.length);
		// Bonus & info card ammount
		const filterAmmountOfExtraCards = () => allItems.value.filter((item) => item.cardType !== 'question');
		const ammountOfExtraCards = computed(() => filterAmmountOfExtraCards().length);
		// Item counter
		const itemCounter = computed(() => activeIndex.value);

		// Counter conditions
		const hasItemsLeft = computed(() => activeIndex.value < allItems.value.length - 1);
		const hasPreviousItems = computed(() => activeIndex.value > 0);
		const nextItem = computed(() => allItems.value[activeIndex.value + 1]);
		const previousItem = computed(() => allItems.value[activeIndex.value - 1]);

		// Jump to last card for express application
		// Calculating -1 because we don't want to count the card we are on
		const findSalaryCardIndex = () => allItems.value.findIndex((card) => card.id === 'personalData');
		const computedSalaryCardIndex = ref(findSalaryCardIndex());

		const findCheckOutCardIndex = () => allItems.value.findIndex((card) => card.id === 'checkOut');
		const computedCheckOutCardIndex = ref(findCheckOutCardIndex());

		const executeExpressToggle = () => {
			expressToggle.value = !expressToggle.value;
			cardState.value = 'forward';
		};

		// Form would otherwise be submited, if you go back from the normal quiz-flow back to the express application flow
		const clearTextInputs = () => {
			if (computedSalaryCardIndex.value === -1) {
				userAnswers.value.personalData.name[0] = '';
				userAnswers.value.personalData.email[0] = '';
				userAnswers.value.checkOut.references[0] = '';
			}
			userAnswers.value.checkOut.salary[0] = '';
			userAnswers.value.personalData.name[0] = '';
			userAnswers.value.personalData.email[0] = '';
			userAnswers.value.checkOut.references[0] = '';
		};

		const enterExpressApplication = () => {
			if (computedSalaryCardIndex.value === -1) {
				activeIndex.value = computedCheckOutCardIndex.value;
				executeExpressToggle();
				return;
			}
			activeIndex.value = computedSalaryCardIndex.value;
			clearTextInputs();
			executeExpressToggle();
		};

		// Extra cards counter is used to calcualte the actual counter without the extra cards
		const visualCardsCounter = ref(0);

		const increaseVisualCardsCounter = () => {
			if (expressToggle.value) {
				return;
			}

			if (nextItem.value.cardType !== 'question') {
				return;
			}

			visualCardsCounter.value++;
		};

		const decreaseVisualCardsCounter = () => {
			if (expressToggle.value) {
				return;
			}

			if (previousItem.value.cardType === 'infoCard') {
				// Resets the counter, otherwise the counter would add 1 everytime we enter and leave an infocard from a question
				visualCardsCounter.value = 0;
				return;
			}

			if (previousItem.value.cardType === 'question' && activeItem.value.cardType === 'expressCard') {
				return;
			}

			if (previousItem.value.cardType === 'question' && activeItem.value.cardType === 'personalDataCard') {
				return;
			}

			if (previousItem.value.cardType === 'personalDataCard' && activeItem.value.cardType === 'expressCard') {
				return;
			}

			visualCardsCounter.value--;
		};

		// Next & previous card to change cards
		const nextCard = () => {
			if (!hasItemsLeft.value) {
				return;
			}
			activeIndex.value++;
			cardState.value = 'forward';
		};

		const previousCard = () => {
			if (expressToggle.value && activeItem.value.id === 'checkOut') {
				cardState.value = 'backward';
				activeIndex.value--;
				return;
			}

			if (expressToggle.value) {
				activeIndex.value = 0;
				executeExpressToggle();
				cardState.value = 'backward';
				return;
			}

			if (hasPreviousItems.value) {
				cardState.value = 'backward';
				activeIndex.value--;
			}
		};

		return {
			activeItem, hasItemsLeft, hasPreviousItems, activeIndex, previousCard, nextCard, allItems, userAnswers, userAnswer, ammountOfCards, itemCounter, ammountOfExtraCards, enterExpressApplication, expressToggle, meta, nextItem, previousItem, visualCardsCounter, increaseVisualCardsCounter, decreaseVisualCardsCounter, findSalaryCardIndex, cardState,
		};
	},
});

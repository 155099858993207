<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 609.9 174.8"
		style="enable-background:new 0 0 609.9 174.8;"
		xml:space="preserve"
		fill="#fff"
	>
		<g id="Layer_3" />
		<g id="Layer_1">
			<g>
				<path
					fill="#ff5600"
					d="M192.8,18.9l-1.9-1.3l-2.2-1l-2.5-0.7l-2.8-0.5l-3-0.2c-8.2,0.1-14.2,1.4-22.1,3.6l-4.1,1.3l-1.8,0.6
			c0,0,0,0,0,0c-0.5,0.2-1.1,0.4-1.6,0.6l-0.7,0.2l-0.3,0.1c-1.1,0.4-2.2,0.8-3.2,1.3l-0.6,0.2l-0.5,0.2c-1.1,0.5-2.2,0.9-3.3,1.4
			l-0.3,0.1l-0.6,0.3c-0.9,0.4-1.8,0.8-2.6,1.3l-0.8,0.4l-0.3,0.2c-1,0.5-2.1,1-3.1,1.6l-0.5,0.3l-0.5,0.3c-1.1,0.6-2.1,1.2-3.1,1.8
			l-0.2,0.1l-0.5,0.3c-0.9,0.5-1.7,1-2.5,1.5l-0.7,0.4l-0.4,0.2c-0.9,0.6-1.9,1.2-2.8,1.8l-0.4,0.3l-0.5,0.4c-1,0.7-1.9,1.4-2.8,2
			l0,0l0,0c-0.9,0.7-1.9,1.4-2.7,2.2l-0.4,0.3l-0.2,0.2c-0.8,0.7-1.7,1.4-2.4,2.1l-0.2,0.2l-0.3,0.3c-0.7,0.7-1.4,1.3-2,2l-0.3,0.3
			l-0.1,0.1c-0.7,0.8-1.4,1.5-2,2.3l-0.2,0.3l-0.2,0.2c-0.6,0.8-1.2,1.5-1.7,2.3l-0.1,0.1l-0.2,0.3c-0.5,0.7-0.9,1.4-1.3,2.1
			l-0.2,0.3l-0.1,0.1c-0.4,0.7-0.7,1.5-1.1,2.2l-0.1,0.3l-0.1,0.3c-0.3,0.8-0.5,1.5-0.7,2.3l0,0l-0.7,2.9l0.4-1.2
			c-0.2,2-0.1,4,0.6,5.9c7.1,20.6,54.1,12.3,38.1,50.4c-10.1,24-35.6,45.6-62.8,56.4c5.6,1.1,11.4,1.7,17.3,1.7
			c48.3,0,87.4-39.1,87.4-87.4c0-14.6-3.6-28.4-10-40.5C185,36.5,194.7,22.2,192.8,18.9z M156.1,24c11-3.6,27.3-7.2,32.2-2.3
			c3.4,3.4-6,15.6-15.2,24.6C168.7,37.9,162.9,30.4,156.1,24z"
				/>
				<path
					fill="#ff5600"
					d="M79.4,132.4l0.4-0.4c0.7-0.7,1.4-1.3,2-2l0.2-0.2l0.2-0.2c0.6-0.7,1.2-1.4,1.8-2.1l0.3-0.4l0.2-0.3
			c0.6-0.7,1.1-1.5,1.6-2.2l0.1-0.2l0.2-0.3c0.4-0.7,0.9-1.3,1.2-2l0.2-0.3l0-0.1c0.4-0.7,0.7-1.5,1-2.2l0.2-0.3l0.1-0.3
			c0.3-0.7,0.5-1.5,0.7-2.2l0-0.1l0.7-2.9l-0.4,1.2c0.2-2,0.1-3.9-0.5-5.8c-6.9-20.8-54.2-12.4-38.1-50.6c8.9-21.3,35-45,63.4-56.6
			C108.8,0.7,102.5,0,96,0C47.7,0,8.6,39.1,8.6,87.4c0,25.1,10.6,47.7,27.5,63.7c-10.5,2.9-25,5.9-29.8,1.1
			c-3.3-3.3,4.2-13.9,13-22.6L19,129C7.7,139.3-1.7,153.2,0.3,156.5l1.9,1.3l2.2,1l2.5,0.7l2.8,0.5l3,0.2c8.2-0.1,14.2-1.4,22.1-3.6
			l4.1-1.3l1.6-0.6c0,0,0,0,0,0c0.6-0.2,1.3-0.5,1.9-0.7l0.6-0.2l0.6-0.3c1-0.4,2-0.8,2.9-1.2l0.5-0.2l0.3-0.1
			c1.2-0.5,2.3-1,3.5-1.5l0.3-0.1l0.5-0.2c1.1-0.5,2.1-1,3.1-1.5l0.4-0.2l0.1-0.1c1.1-0.5,2.1-1.1,3.2-1.6l0.7-0.3l0.6-0.3
			c1-0.6,2-1.1,3-1.7l0.3-0.1l0.6-0.4c0.8-0.5,1.5-0.9,2.3-1.4l0.8-0.5l0.4-0.2c0.9-0.6,1.9-1.2,2.8-1.8l0.4-0.3l0.4-0.3
			c0.9-0.6,1.7-1.2,2.5-1.8l0.4-0.3l0.6-0.5c0.7-0.5,1.3-1.1,2-1.6l0.5-0.4l0.3-0.2c0.8-0.7,1.6-1.4,2.3-2L79.4,132.4z"
				/>
			</g>
		</g>
		<g id="Layer_2">
			<path
				d="M593.1,93.8c0,5-1.3,9.2-3.9,12.5c-2.6,3.3-6.6,5-11.8,5h-12.2V76.9h12.2c5.5,0,9.5,1.5,12,4.4
		C591.9,84.3,593.1,88.4,593.1,93.8 M609.9,93.3c0-9.5-2.7-16.8-8-21.8c-5.3-5-12.7-7.5-22.1-7.5h-14.7V41.9h-16.5v82.2h26.9
		c3.9,0,7.5-0.2,11-0.6c3.5-0.4,6.9-1.7,10.2-3.8c4.7-3,8.1-6.7,10.1-11.3C608.9,103.7,609.9,98.7,609.9,93.3z M516.7,111.3h-19.1
		c-1,0-2,0-3.1-0.1c-1.1-0.1-2-0.3-2.9-0.6c-0.9-0.3-1.6-0.8-2.1-1.5c-0.5-0.7-0.8-1.7-0.8-3c0-1.9,0.6-3.3,1.8-4.2
		c1.2-0.9,2.7-1.4,4.4-1.4h21.8V111.3z M533.2,124.1V90.3c0-2.1-0.1-4.2-0.2-6.2c-0.1-2-0.5-4-1.2-6c-1.6-5.2-4.3-8.8-7.9-10.7
		c-3.6-2-8-3-13.1-3.2c-1.2,0-2.3,0-3.4-0.1c-1.1,0-2.2-0.1-3.4-0.1h-27.9v12.8h27.9c1.9,0,3.6,0.1,5.2,0.3c1.6,0.2,2.9,0.7,4,1.4
		c1.1,0.7,2,1.7,2.5,3c0.6,1.3,0.9,3,0.9,5.1v1h-20.2c-1.3,0-2.8,0-4.3,0.1c-1.6,0-3.1,0.2-4.7,0.4c-1.6,0.2-3.1,0.5-4.6,1
		c-1.5,0.4-2.8,1.1-3.8,1.9c-2.4,1.8-4.2,4.1-5.4,6.9c-1.2,2.8-1.8,5.6-1.8,8.5c0,3.7,1,7.2,3,10.4c2,3.2,4.8,5.4,8.6,6.3
		c2.1,0.6,4.3,0.9,6.6,0.9c2.3,0,4.5,0.1,6.7,0.1H533.2z M459,124.1V41.9h-16.5v82.2H459z M427.1,124.1V87.2c0-8-1.8-13.8-5.5-17.6
		c-3.7-3.7-9.5-5.6-17.4-5.6h-32.9v60h16.5V76.9H402c3,0,5.2,0.6,6.5,1.9c1.3,1.3,2,3.4,2,6.5v38.8H427.1z M354.9,124.1v-60h-16.5
		v47.2h-15.5c-2.6,0-4.6-0.8-6-2.4c-1.4-1.6-2-3.7-2-6.2V64.1h-16.5v37c0,7,1.6,12.6,4.9,16.8c3.3,4.2,8.6,6.3,16,6.3H354.9z
		M286.3,105.1c0-3.1-0.6-5.8-1.7-8c-1.1-2.2-2.6-3.9-4.5-5.3c-1.9-1.4-4.1-2.3-6.6-3c-2.5-0.6-5.2-0.9-8-0.9h-9.7
		c-1.1,0-2.2,0-3.5-0.1c-1.3,0-2.5-0.2-3.5-0.6c-1.1-0.3-1.9-0.9-2.7-1.7c-0.7-0.8-1-2-1-3.5c0-2.4,0.7-3.8,2-4.4
		c1.4-0.5,3-0.8,4.9-0.8c0.4,0,0.8,0,1.2-0.1c0.4,0,0.8-0.1,1.2-0.1h29.2V64.1h-35.3c-3.4,0-6.4,0.3-8.9,0.9c-2.6,0.6-5,2.2-7.2,4.9
		c-2.7,3.4-4.1,7.3-4.1,11.7c0,3.9,0.9,7.4,2.8,10.5c1.9,3.2,4.7,5.4,8.4,6.8c1.4,0.5,3,0.8,4.8,1c1.8,0.2,3.7,0.3,5.6,0.4
		c1.9,0,3.8,0,5.6,0c1.8,0,3.4-0.1,4.8-0.1c1.2,0,2.3,0,3.5,0.1c1.2,0,2.3,0.2,3.3,0.6c1,0.3,1.8,0.9,2.4,1.7c0.6,0.8,0.9,2,0.9,3.6
		c0,2.1-0.6,3.5-1.9,3.9c-1.3,0.5-2.8,0.8-4.6,1c-0.9,0.1-1.8,0.1-2.7,0.1c-0.8,0-1.7,0-2.6,0h-29.3v12.8h34c1.7,0,3.5,0,5.4-0.1
		c1.8,0,3.6-0.3,5.4-0.7c2.1-0.4,3.8-1.2,5.4-2.5c1.5-1.2,2.8-2.7,3.9-4.4c1.1-1.7,1.9-3.5,2.4-5.5
		C286,109.1,286.3,107.1,286.3,105.1z"
			/>
		</g>
	</svg>
</template>

import {
	Card, InfoCard,
} from '@/models/Card';

import { TextField, SelectField } from '@/models/CardField';

// All fields –––––––––––––––––––––––––––––––––––––––––––––––

const jobName = 'Automation Developer';

const graduationField: SelectField = {
	id: 'gradutation',
	type: 'checkbox',
	hasIcons: true,
	options: [
		{
			label: 'Selbststudium',
			value: 'self-study',
			iconFileName: 'icon-brain.svg',
		},
		{
			label: 'Ausbildung',
			value: 'apprenticeship',
			iconFileName: 'icon-keyboard.svg',
		},
		{
			label: 'Studium',
			value: 'studies',
			iconFileName: 'icon-university.svg',
		},
	],
};
const experienceField: SelectField = {
	id: 'years',
	type: 'radio',
	hasIcons: true,
	options: [
		{
			label: '0-2 Jahre',
			value: '0-2',
			iconFileName: 'icon-chess-pawn.svg',
		},
		{
			label: '2-4 Jahre',
			value: '2-4',
			iconFileName: 'icon-chess-knight.svg',
		},
		{
			label: '4-6 Jahre',
			value: '4-6',
			iconFileName: 'icon-chess-rook.svg',
		},
		{
			label: 'Über 6 Jahre',
			value: '6+',
			iconFileName: 'icon-chess-queen.svg',
		},
	],
};
const toolsField: SelectField = {
	id: 'tool',
	type: 'checkbox',
	hasIcons: true,
	options: [
		{
			label: '.NET',
			iconFileName: 'icon-net.svg',
			value: '.NET',
		},
		{
			label: 'ASP.NET (C#)',
			iconFileName: 'icon-asp.svg',
			value: 'ASP.NET (C#)',
		},
		{
			label: 'SQL',
			iconFileName: 'icon-sql.svg',
			value: 'SQL',
		},
		{
			label: 'Javascript',
			iconFileName: 'icon-js.svg',
			value: 'Javascript',
		},
	],
};
const nameField: TextField = {
	type: 'text',
	id: 'name',
	label: 'Name',
	value: 'name',
	placeholder: 'Name eingeben',
	rules: {
		required: true,
		alpha_spaces: true,
		min: 2,
	},
};
const emailField: TextField = {
	type: 'text',
	id: 'email',
	label: 'E-Mail',
	value: 'email',
	placeholder: 'E-Mail eingeben',
	inputmode: 'email',
	rules: {
		required: true,
		email: true,
		min: 2,
		max: 300,
	},
};
const referencesField: TextField = {
	type: 'text',
	id: 'references',
	label: 'Account-Name',
	value: 'references',
	placeholder: 'z.B. LinkedIn oder Xing',
	inputmode: 'text',
	rules: {
		required: true,
		min: 2,
		max: 1000,
	},
};
const salaryField: TextField = {
	type: 'text',
	id: 'salary',
	label: 'Deine Gehaltsvorstellung',
	value: 'salary',
	placeholder: 'Bruttogehalt/Jahr',
	inputmode: 'numeric',
	rules: {
		required: true,
		numeric: true,
		min: 2,
	},
};

// All cards ––––––––––––––––––––––––––––––––––––––––––––––––

const openingCard: InfoCard = {
	cardType: 'infoCard',
	id: 'opening',
	outlineTitle: 'Deine Aufgaben',
	title: 'in unserem Team',
	videoFileName: 'IJA_Astronaut.mp4',
	buttonText: 'Mehr zu Sunlab',
	fields: [],
	infoList: [
		{
			icon: '💻',
			text: 'Weiterentwicklung der von uns entwickelten Automatisierungssoftware',
		},
		{
			icon: '🔀',
			text: 'Anknüpfung an neue APIs (z.B. Pinterest, TikTok etc.)',
		},
		{
			icon: '⌨️',
			text: 'Technische Betreuung von Automatisierten Online Marketing Kampagnen',
		},
		{
			icon: '🏗',
			text: 'Instandhaltung und Maintenance genutzter Funktionen',
		},
	],
};
const aboutCard: InfoCard = {
	cardType: 'infoCard',
	id: 'about',
	outlineTitle: jobName,
	title: 'bei Sunlab',
	videoFileName: 'IJA_Astronaut.mp4',
	buttonText: 'Passen wir zusammen?',
	fields: [],
	infoList: [
		{
			icon: '💻',
			text: 'Arbeiten mit modernsten Technologien',
		},
		{
			icon: '❤️',
			text: 'Familiäres Arbeitsklima',
		},
		{
			icon: '🏡',
			text: 'Flexibles und mobiles Arbeiten',
		},
		{
			icon: '🙌',
			text: 'Spannendes und abwechslungsreiches Kundenportfolio',
		},
	],
};
const graduationCard: Card = {
	cardType: 'question',
	title: 'Vorerfahrung (Wie hat deine Coding-Journey begonnen?)',
	id: 'codingJourney',
	note: 'Du kannst mehrere auswählen',
	fields: [
		graduationField,
	],
};
const experienceCard: Card = {
	cardType: 'question',
	id: 'experience',
	title: 'Wie viel Berufserfahrung hast du?',
	note: 'Du kannst eins auswählen',
	fields: [
		experienceField,
	],
};
const toolsCard: Card = {
	cardType: 'question',
	id: 'usedTools',
	title: 'Welche dieser Technologien beherrschst du?',
	note: 'Du kannst mehrere auswählen',
	fields: [
		toolsField,
	],
};
const personalDataCard: Card = {
	cardType: 'personalDataCard',
	id: 'personalData',
	emoji: '😎',
	outlineTitle: 'Bewerbung als',
	note: 'Alle Felder sind verpflichtend',
	title: (answers, meta) => {
		if (meta.express) {
			return jobName;
		}

		return 'Der erste Eindruck ist positiv! Bewirb dich doch bei uns';
	},
	fields: [
		nameField,
		emailField,
	],
};
const expressCard: Card = {
	cardType: 'expressCard',
	id: 'checkOut',
	outlineTitle: 'Bewerbung als',
	note: 'Alle Felder sind verpflichtend',
	title: (answers, meta) => {
		if (meta.express) {
			return jobName;
		}

		return jobName;
	},
	fields: [
		referencesField,
		salaryField,
	],
};
const lastCard: InfoCard = {
	cardType: 'successCard',
	id: 'success',
	outlineTitle: 'Deine Bewerbung',
	title: 'ist unterwegs!',
	videoFileName: 'IJA_Rocket.mp4',
	emoji: '🚀',
	fields: [],
	infoList: [
		{
			icon: '📅',
			text: 'Innerhalb von 2 Wochen hörst Du von uns',
		},
		{
			icon: '❓',
			text: 'Offene Fragen kannst Du an info@sunlab.de stellen',
		},
	],
};

export const automationDeveloperCards: Card[] = [
	openingCard,
	aboutCard,
	graduationCard,
	experienceCard,
	toolsCard,
	// Should not be moved, they're necessary for the express application logic
	personalDataCard,
	expressCard,
	lastCard,
];

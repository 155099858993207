<template>
	<div
		class="text-white-500 mx-4 pt-8 shadow-2xl text-center h-full max-h-[1080px]"
	>
		<VeeForm
			v-slot="{ meta: formMeta }"
			as=""
		>
			<form
				class="h-full"
				@submit.prevent=""
			>
				<div class="bg-white-100 px-8 py-6 rounded-3xl flex flex-col justify-between h-full desktop:py-12">
					<div class="flex flex-col">
						<div class="phablet:mb-4">
							<div class="">
								<h2 :class="['text-white-500', { 'font-bold text-xl desktop:text-3xl': item.cardType === 'question'}, { 'font-medium text-2xl desktop:text-4xl': item.cardType === 'infoCard' || item.cardType === 'expressCard' || item.cardType === 'personalDataCard' || item.cardType === 'successCard'}, { 'mb-6': item.cardType === 'personalDataCard' || item.cardType === 'expressCard' || item.cardType === 'successCard'},]">
									<span
										:class="['text-stroke text-transparent font-medium', { 'hidden': item.cardType === 'personalDataCard' && !expressToggle }, { 'hidden': !item.outlineTitle }]"
									>
										{{ item.outlineTitle }} <br>
									</span>
									{{ computedTitle }}
								</h2>
								<div
									v-if="item.emoji"
									class="text-[48px]"
								>
									{{ item.emoji }}
								</div>
								<ul
									v-if="item.infoList"
									:class="['font-normal text-white-400 text-left mt-10 phablet:px-10', { 'px-2': item.cardType !== 'successCard' }, { 'px-6': item.cardType === 'successCard' } ]"
								>
									<li
										v-for="infoListItem in item.infoList"
										:key="infoListItem.text"
										class="flex mb-4 last:mb-0 items-center desktop:mb-6"
									>
										<div class="text-2xl mr-6">
											{{ infoListItem.icon }}
										</div>
										<p class="text-sm">
											{{ infoListItem.text }}
										</p>
									</li>
								</ul>
							</div>
							<div
								v-if="item.cardType === 'question'"
								:class="['flex items-center px-12 mt-4 mb-6 phablet:px-20 max-w-[400px] mx-auto', { 'justify-between': item.cardType === 'question' }, { 'justify-center': item.cardType != 'question' } ]"
							>
								<p
									v-if="item.cardType === 'question'"
									class="text-sm text-white-400 font-light w-12 whitespace-nowrap mr-4 desktop:text-lg"
								>
									{{ questionsLeft + ' / ' + questionsTotal }}
								</p>
								<div
									v-if="item.cardType === 'question'"
									class="w-full"
								>
									<div class="relative w-full h-[10px] rounded-[5px] z-10">
										<div
											:style="`width:${questionFullPercentage}%`"
											class="absolute bg-orange-500 h-2 rounded-full z-10 desktop:h-3"
										/>
										<div class="absolute bg-white-500 w-full h-2 rounded-full z-0 desktop:h-3" />
									</div>
								</div>
							</div>
						</div>
						<div
							class="px-4 desktop:px-0 flex flex-col justify-center mb-6"
						>
							<div
								v-for="field in item.fields"
								:key="field.id"
							>
								<div
									v-if="field.type === 'text'"
									class="grid w-full mx-auto grid-cols-1 max-w-sm"
								>
									<AppInput
										v-model="computedValue[field.id]"
										:type="field.type"
										:name="field.value"
										:value="field.value"
										:label="field.label"
										:rules="field.rules"
										:placeholder="field.placeholder"
										:inputmode="field.inputmode"
										class="mb-6 desktop:mb-8"
									/>
								</div>
								<div
									v-if="field.type === 'radio'"
									:class="['grid mx-auto gap-4 max-w-sm desktop:gap-6', { 'grid-cols-2': field.hasIcons }, { 'grid-cols-1': !field.hasIcons } ]"
								>
									<AppSingleSelect
										v-for="option in field.options"
										:key="option.value"
										v-model="computedValue[field.id]"
										:value="option.value"
										:type="field.type"
										:label="option.label"
										:has-slider="field.hasSlider"
										:has-icons="field.hasIcons"
										:icon-file-name="option.iconFileName"
										class="h-[-8px]"
									/>
								</div>
								<div
									v-if="field.type === 'checkbox'"
									:class="['grid mx-auto gap-4 w-full max-w-sm desktop:gap-6', { 'grid-cols-2': field.hasIcons }, { 'grid-cols-1': !field.hasIcons }]"
								>
									<AppMultipleSelect
										v-for="option in field.options"
										:key="option.value"
										v-model="computedValue[field.id]"
										:type="field.type"
										:value="option.value"
										:label="option.label"
										:icon-file-name="option.iconFileName"
										:has-icons="field.hasIcons"
										class="h-[-8px]"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						class="flex flex-col justify-end"
					>
						<div>
							<p
								v-if="item.note"
								class="text-[10px] font-light text-white-400 mb-2 desktop:text-base"
							>
								{{ item.note }}
							</p>
							<div
								v-if="item.id === 'personalData' || item.id === 'checkOut'"
								class="flex"
							>
								<AppButton
									:class="[{'pointer-events-none opacity-50': !formMeta.valid }]"
									full-width-button
									@click="increaseVisualCardsCounter($event), onSubmit(formMeta), formMeta.valid ? itemNext($event) : ''"
								>
									{{ item.id === 'checkOut' ? 'Bewerben' : 'Weiter' }}
								</AppButton>
							</div>
							<div
								v-if="item.id === 'personalData' || item.id === 'checkOut' || item.id === 'success' ? false : true"
								class="flex"
							>
								<AppButton
									:class="[{'pointer-events-none opacity-50': fieldIsSelected === 0}]"
									full-width-button
									@click="isCardValid ? increaseVisualCardsCounter($event) : '', isCardValid ? itemNext($event) : ''"
								>
									{{ item.buttonText ? item.buttonText : 'Weiter' }}
								</AppButton>
							</div>
						</div>
						<p
							v-if="item.cardType === 'infoCard'"
							class="mt-4 text-white-300 font-light text-sm desktop:text-base"
						>
							Liebe auf den ersten Blick?
						</p>
						<p
							v-if="item.cardType === 'infoCard'"
							class="mt-1 text-orange-500 underline text-sm cursor-pointer desktop:text-base"
							@click="lastCard"
						>
							Direkt bewerben!
						</p>
					</div>
				</div>
			</form>
		</VeeForm>
	</div>
</template>

<script>
import { computed } from 'vue';
import { Form } from 'vee-validate';
import { getCardTitle } from '@/models/Card';
import AppButton from './AppButton.vue';
import AppInput from './AppInput.vue';
import AppSingleSelect from './AppSingleSelect.vue';
import AppMultipleSelect from './AppMultipleSelect.vue';
import { addNewCandidate } from '../api/main';

export default {
	components: {
		AppButton,
		AppInput,
		AppSingleSelect,
		AppMultipleSelect,
		VeeForm: Form,
	},

	props: {
		item: {
			type: Object,
			required: true,
		},

		itemAmmount: {
			type: Number,
			required: true,
		},

		itemsLeft: {
			type: Boolean,
			required: true,
		},

		itemNext: {
			type: Function,
			required: true,
		},

		itemBack: {
			type: Function,
			required: true,
		},
		counter: {
			type: Number,
			required: true,
		},

		lastCard: {
			type: Function,
			required: true,
		},

		answers: {
			type: Object,
			required: true,
		},

		meta: {
			type: Object,
			required: true,
		},

		extraCardsAmmount: {
			type: Number,
			required: true,
		},

		visualCardsCounter: {
			type: Number,
			required: true,
		},

		increaseVisualCardsCounter: {
			type: Function,
			required: true,
		},

		decreaseVisualCardsCounter: {
			type: Function,
			required: true,
		},

		expressToggle: {
			type: Boolean,
			required: true,
		},

		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},

	emits: ['update:modelValue'],

	setup(props, { emit }) {
		const activeItemId = computed(() => props.item.id);

		const computedValue = computed({
			get() {
				return props.modelValue;
			},
			set(value) {
				emit('update:modelValue', value);
			},
		});

		const fieldIsSelected = computed(() => {
			if (props.item.cardType === 'question') {
				return computedValue.value[props.item.fields[0].id].length;
			} return false;
		});

		// Calculate width of progress bar
		const questionsLeft = computed(() => props.visualCardsCounter);
		const questionsTotal = computed(() => props.itemAmmount - props.extraCardsAmmount);
		const questionDezimalPercentage = computed(() => questionsLeft.value / questionsTotal.value);
		const questionFullPercentage = computed(() => questionDezimalPercentage.value * 100);

		const computedTitle = computed(() => getCardTitle(props.item, props.answers, props.meta));

		// Check if we can jump to the next card

		const isCardValid = computed(() => {
			if (props.item.cardType !== 'question') {
				return true;
			}

			if (computedValue.value[props.item.fields[0].id].length > 0) {
				return true;
			}

			return false;
		});

		const onSubmit = async (meta) => {
			if (!meta.valid && !meta.dirty) {
				return;
			}

			if (props.answers.salary && !props.answers.checkOut.salary[0]) {
				return;
			}

			if (!props.answers.personalData.name[0]) {
				return;
			}

			if (!props.answers.personalData.email[0]) {
				return;
			}

			if (!props.answers.checkOut.references[0]) {
				return;
			}

			if (activeItemId.value === 'checkOut') {
				try {
					addNewCandidate(props.answers);
				} catch (error) {
					// eslint-disable-next-line
					console.error(error);
				}
				// eslint-disable-next-line
				console.log(props.answers);
			}
		};

		return {
			computedTitle, onSubmit, questionsLeft, questionsTotal, questionFullPercentage, activeItemId, computedValue, fieldIsSelected, isCardValid,
		};
	},
};
</script>

<style>

    .text-stroke {
        -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
    }

</style>

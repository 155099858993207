import { createApp } from 'vue';
import { defineRule, configure } from 'vee-validate';
import {
	// eslint-disable-next-line camelcase
	required, email, min, max, alpha_spaces, numeric,
} from '@vee-validate/rules';

import { localize } from '@vee-validate/i18n';

import App from './App.vue';
import './index.css';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('alpha_spaces', alpha_spaces);
defineRule('max', max);
defineRule('numeric', numeric);

configure({
	generateMessage: localize({
		en: {
			messages: {
				alpha_spaces: 'Nur alphanumerische Zeichen erlaubt.',
				email: 'Geben Sie eine gültige E-Mail Adresse ein.',
				required: 'Dieses Feld ist verpflichtend.',
				min: 'Mindestens 2 Zeichen.',
				max: 'Maximale Zeichenanzahl überschritten.',
				numeric: 'Nur numerische Zeichen erlaubt.',
			},
		},
	}),
});

createApp(App).mount('#app');
